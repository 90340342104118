import React, { useEffect, useState, useContext } from 'react';
import {Link} from "react-router-dom"
import BTC from '../../btc.gif';
import LITE from '../../litecoin.gif';
import ETH from '../../ethereum.gif';
import GOLD from '../../goldMoney.gif';
import NET from '../../netPay.gif';
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {MainContext} from "../../App"

const UserFunds = () => {
const CTX = useContext(MainContext)
const userID = useParams().id
  const username = useParams().username
  const [loading, setLoading] = useState(false)
  const [mainUser, setMainUser] = useState({wallet: {bitcoin: {}}})

  const fetchUser = () => {
    setLoading(true)
    let TOASTID = toast.loading("Settings up contents");
    fetch(`${CTX.url}admin/action/user/${userID}`, {
      method: "GET",
      headers: new Headers({"Content-Type" : "application/json", 
      "Authorization": `bearer ${CTX.token}`}),
    })
    .then(res => res.json())
    .then(res => {
    setLoading(false)
    toast.dismiss(TOASTID); // stop the loading icon
    toast.success("Contents loaded successfully");
    setMainUser(res.data)
    // setCurrentLimit(res.previous_page * limit)
  })
  .catch(e => {
      setLoading(false)
      toast.dismiss(TOASTID);
      toast.error("Error check your internet connection");
      console.log(e);
    })
  }

  useEffect(() => {
    fetchUser()
  }, [])


  // const mapAllCoins = Object.keys(mainUser?.wallet).map((v,i) => (
  //   <tr key={i}>
  //   <th style={{textAlign: 'right', textTransform: "uppercase"}}>
  //     {v}
  //     <img src={mainUser?.wallet[v]?.image} height="17" width="44" align="absmiddle" />
  //   </th>
  //   <td>${mainUser?.wallet[v]?.balance}</td>
  //   <td>
      
  //     <i>{mainUser?.wallet[v]?.address}</i>
  //   </td>
  // </tr>
  // ))


  // console.log("mainUser", mainUser)
  // console.log("mainUser", mainUser)
  // console.log("mainUser", mainUser)

  return (
    <table width="100%" height="100%" class="forTexts">
      <Toaster position="top-right" />
      <tbody>
        <tr>
          {loading ? "" : 
          <td width="100%" height="100%" valign="top">
            <h3>User Details:</h3>
            <table class="form">
              
              <tbody>
                <tr>
                  
                  <th>Username:</th> <td>{mainUser?.username}</td>
                </tr>
                <tr>
                  
                  <th>Full Name:</th> <td>{mainUser?.full_name}</td>
                </tr>
                <tr>
                  
                  <th>E-mail:</th>
                  <td>
                    <a href={`mailto:${mainUser?.email}`}>{mainUser?.email}</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
           
            <br />
            <table class="form">
              
              <tbody>
                <tr>
                  
                  <th>Total Balance:</th>
                  <td>
                    ${mainUser?.balance} 
                    {/* <div class="sbmt-group">
                      <Link
                      style={{float: "right"}}
                        to="/transactions/:id"
                        class="sbmt btn-sm btn-info"
                      >
                        history
                      </Link>
                    </div> */}
                  </td>
                </tr>
                <tr>
                  
                  <th>Total Deposit:</th>
                  <td>
                    ${mainUser?.total_deposit}
                    
                  </td>
                </tr>
                <tr>
                  
                  <th>Active Deposit:</th>
                  <td>
                    ${mainUser?.active_deposit}
                    <div class="sbmt-group">
                      <Link
                        style={{float: 'right'}}
                        to={`/user-deposit/${userID}`}
                        class="sbmt btn-sm btn-danger"
                        
                      >
                        manage deposits
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  
                  <th>Total Earning:</th>
                  <td>
                    ${mainUser?.total_earning}
                    {/* <div class="sbmt-group">
                      <Link
                        style={{float: 'right'}}
                        to="/earning/:id"
                        class="sbmt btn-sm btn-info"
                      >
                        history
                      </Link>
                    </div> */}
                  </td>
                </tr>
                <tr>
                  
                  <th>Total Withdrawal:</th>
                  <td>
                    ${mainUser?.total_withdrawal}
                    {/* <div class="sbmt-group">
                      <Link
                        style={{float: 'right'}}
                        to="/withdrawal/:id"
                        class="sbmt btn-sm btn-info"
                      >
                        history
                      </Link>
                    </div> */}
                  </td>
                </tr>
                <tr>
                  
                  <th>Pending Withdrawals:</th>
                  <td>
                    ${mainUser?.pending_withdrawal}
                    
                    <div class="sbmt-group">
                      <Link
                        style={{float: 'right'}}
                        to="/withdrawal_requests/:id"
                        class="sbmt btn-sm btn-danger"
                      >
                        process withdrawals
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  
                  <th>Total Bonus:</th>
                  <td>
                    ${mainUser?.total_bonus}
                    {/* <div class="sbmt-group">
                      
                      <Link
                        to={`/add_transactions/${mainUser._id}/${mainUser.username}?type=bonus`}
                        style={{float: 'right'}}
                        class="sbmt btn-sm btn-danger"
                      >
                        add a bonus
                      </Link>
                    </div> */}
                  </td>
                </tr>
                {/* <tr>
                  
                  <th>Total Penalty:</th>
                  <td>
                    ${mainUser?.total_penalty}
                    <div class="sbmt-group">
                      
                      <Link
                        style={{float: 'right'}}
                        to={`/add_transactions/${mainUser._id}/${mainUser.username}?type=penalty`}
                        class="sbmt btn-sm btn-danger"
                      >
                        add a penalty
                      </Link>
                      <Link
                        to="/penalty/:id"
                        style={{float: 'right'}}
                        class="sbmt btn-sm btn-info"
                      >
                        history
                      </Link>
                    </div>
                  </td>
                </tr> */}
                {/* <tr>
                  
                  <th>Referrals 1st Level:</th>
                  <td>
                    0
                    <div class="sbmt-group">
                      
                      <Link
                        to="/user-referral/:id"
                        style={{float: 'right'}}
                        class="sbmt btn-sm btn-primary"
                      >
                        referrals
                      </Link>
                      <Link
                        to="/user-traffic/:id"
                        style={{float: 'right'}}
                        class="sbmt btn-sm btn-success"
                      >
                        traffic
                      </Link>
                    </div>
                  </td>
                </tr> */}
                <tr>
                  
                  <th>Referral Commissions:</th>
                  <td>
                    ${mainUser.referral_commission}
                    {/* <div class="sbmt-group">
                      <Link
                        style={{float: 'right'}}
                        to="/referral-commission/:id"
                        class="sbmt btn-sm btn-info"
                      >
                        history
                      </Link>
                    </div> */}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="alert alert-warning">
              
              Manage user funds:
              <br /> Account balance: how many funds can the user deposit to any
              investment package or withdraw from the system.
              <br /> Total deposit: how many funds has the user ever deposited
              to your system.
              <br /> Total active deposit: the whole current deposit of this
              user.
              <br /> Total earnings: how many funds has the user ever earned
              with your system.
              <br /> Total withdrawals: how many funds has the user ever
              withdrawn from system.
              <br /> Total bonus: how many funds has the administrator ever
              added to the user account as a bonus.
              <br /> Total penalty: how many funds has the administrator ever
              deleted from the user account as a penalty.
              <br /> Actions:
              <br /> Transactions history - you can check the transactions
              history for this user.
              <br /> Active deposits/Transactions history - you can check the
              deposits history for this user.
              <br /> Earnings history - you can check the earnings history for
              this user.
              <br /> Withdrawals history - you can check the withdrawals history
              for this user.
              <br /> Process withdrawals - you can withdraw funds by clicking
              this link if a user asked you for a withdrawal.
              <br /> Bonuses history - you can check the bonuses history for
              this user.
              <br /> Penalties history - you can check the penalties history for
              this user.
              <br /> Add a bonus and add a penalty - add a bonus or a penalty to
              this user.
              <br />
            </div>
          </td>
          }
        </tr>
      </tbody>
    </table>
  );
};

export default UserFunds;
