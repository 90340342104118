import React, {useContext, useState, useEffect} from 'react';
import Classes from './investmentPlans.module.css';
import {MainContext} from '../../App';
import {ReactComponent as TRASH} from '../../assets/trash.svg';
import toast, {Toaster} from 'react-hot-toast';

const InvestmentPlans = () => {
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  const fetchAllUser = () => {
    if(loading) {
      return
    }
    setLoading(true);
    let TOASTID = toast.loading('Fetching company plan');
    fetch(`${CTX.url}admin/account/plans`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('Company plans ');
        setPlans(res.plans.plans);
        // console.log('res.data => ', res.plans.plans);
        setLoading(false);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  const onChangeHandler = (index, e) => {
    const spread = [...plans];
    console.log("index => ", index);
    spread[index][e.target.name] = e.target.value;
    setPlans(spread)
  };

  const trashHandler = (index, e) => {
    const spread = [...plans]
    const ss = spread.splice(index, 1)
    setPlans(spread)
  }

  const mapPlans = plans.map((v, i) => {
    return (
      <div style={{border: '1px solid #333', position: 'relative'}}>
        <div className={Classes.absoluteDelete}>
          <TRASH style={{cursor: "pointer"}} onClick={trashHandler.bind(this, i)} width="30px" />{' '}
        </div>
        <div className={Classes.coverTheMap}>
          <div className={Classes.coverTheEachHere}>
            <label>Plan Name</label>
            <input
              value={v?.name}
              onChange={onChangeHandler.bind(this, i)}
              name={'name'}
            />
          </div>

          <div className={Classes.coverTheEachHere}>
            <label>Min</label>
            <input
              value={v?.min}
              type="number"
              onChange={onChangeHandler.bind(this, i)}
              name="min"
            />
          </div>

          <div className={Classes.coverTheEachHere}>
            <label>Max</label>
            <input
              value={v?.max}
              onChange={onChangeHandler.bind(this, i)}
              name="max"
            />
          </div>

          <div className={Classes.coverTheEachHere}>
            <label>Time</label>
            <input
              value={v?.time}
              onChange={onChangeHandler.bind(this, i)}
              name="time"
            />
          </div>

          <div className={Classes.coverTheEachHere}>
            <label>Percentage </label>
            <input
              value={v?.rio}
              type="number"
              onChange={onChangeHandler.bind(this, i)}
              name="rio"
            />
          </div>

          <div className={Classes.coverTheEachHere}>
            <label>Seconds </label>
            <input
              type="number"
              value={v?.seconds}
              onChange={onChangeHandler.bind(this, i)}
              name="seconds"
            />
          </div>
        </div>

        <div
          className={Classes.coverTheEachHere}
          style={{width: '84%', marginLeft: '0px'}}
        >
          <label>Note </label>
          <textarea
            value={v.note}
            rows="4"
            onChange={onChangeHandler.bind(this, i)}
            name="note"
          ></textarea>
        </div>
      </div>
    );
  });


  const submitHandler = () => {
    if(loading){
      return
    }
    setLoading(true);
    let TOASTID = toast.loading('Posting new plan');
    fetch(`${CTX.url}admin/account/plans`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
      body: JSON.stringify({
        plans: plans
      })
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('Successful ');
        setPlans(res.date.plans);
        // console.log('res.data => ', res);
        // console.log('res.data => ', res);
        setLoading(false);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  }



  const addSubmitHandler = () => {
    const spread = [...plans]
    spread.push({})
    setPlans(spread)
      }

  return (
    <div style={{paddingBottom: '3em'}}>
      <div>{mapPlans}</div>

      {/* <button onClick={submitHandler} style={{marginTop: '20px'}}>Submit</button>


      <button onClick={addSubmitHandler} style={{marginTop: '20px', marginLeft: "20px"}}>
        Add another section
      </button> */}
    </div>
  );
};

export default InvestmentPlans;
