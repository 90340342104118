import React, { useState } from "react";
import Input from "../../input/input";
// import {MainContext} from '../../App';
// import axios from 'axios';
import Loading from "../../loading/loading";
import Classes from "./login.module.css";

const Login = ({ OnSubmitFormHandler }) => {
  // const CTX = useContext(MainContext);
  const [checking, setChecking] = useState(true);
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  // onSubmitFormHandler for submitting login
  // const OnSubmitFormHandler = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const request = await axios.post(`${CTX.url}/agent/login`, {
  //       email: inputs.email,
  //       password: inputs.password,
  //     });
  //     setLoading(false);
  //     CTX.setToken(request.data.data.refresh_token);
  //     CTX.setUser(request.data.data.agent);
  //   } catch (error) {
  //     setLoading(false);
  //     setMsg("Invalid login details")
  //     console.log(error);
  //   }
  // };

  return (
    <div className={Classes.loginCover}>
      <div className={Classes.cardBody}>
        <img
          src="https://ik.imagekit.io/7p9j0gn28d3j/logo_-G3MhxATx.png?updatedAt=1699798499641"
          alt="logo"
          width="207.47"
          height="42.45"
          style={{ width: "207.47px", height: "42.45px" }}
        />
        <h3 className={Classes.header}> Login</h3>
        {msg.length < 1 && (
          <small className={Classes.small}>
            Enter your Username to access panel.
          </small>
        )}
        {msg.length > 0 && (
          <div
            className={Classes.msgAlert}
            style={{ textAlign: "center", marginBottom: "20px", width: "100%" }}
          >
            <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
              {msg}
            </p>
          </div>
        )}
        <form
          onSubmit={OnSubmitFormHandler.bind(
            this,
            inputs?.email,
            inputs?.password
          )}
          style={{ marginTop: "17px" }}
        >
          <Input
            value={inputs?.email}
            onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            type="input"
            required
            label="Username"
          />
          <div style={{ height: "8px" }}></div>
          <Input
            value={inputs?.password}
            onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
            type="input"
            label="Password"
            inputType="password"
            required
          />
          <div
            style={{ display: "flex" }}
            onClick={() => setChecking(!checking)}
          >
            <input
              type="checkbox"
              className={Classes.checkbox}
              checked={checking}
              onChange={() => setChecking(!checking)}
            />
            <small
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                marginTop: "4px",
                paddingBottom: "20px",
                color: "#6c757d",
              }}
            >
              Remember me
            </small>
          </div>
          <button disabled={loading} className={Classes.button}>
            <span>Log In</span>
            {loading && (
              <Loading
                size="1.4em"
                fill="#fff"
                style={{ margin: "20px 0px 0px 20px" }}
              />
            )}
          </button>
        </form>
      </div>
      <center></center>
    </div>
  );
};

export default Login;
