import React, { useContext, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { MainContext } from "../../App";
import { Link } from "react-router-dom";

const PendingDeposit = () => {
  const CTX = useContext(MainContext);
  const [nextPage, setNextPage] = useState(1);
  const [filterCU, setFilterCU] = useState("all");
  const [loading, setLoading] = useState(false);
  const [deposits, setDeposit] = useState([]);
  const [selected, setSelected] = useState(false);

  const fetchAllDeposits = () => {
    setLoading(true);
    let TOASTID = toast.loading("Settings up contents");
    fetch(`${CTX.url}admin/action/list-deposit?page=1&limit=99999999999999`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success("All deposits ");
        setDeposit(res.allDeposit);
        setLoading(false);
        setNextPage(res.next_page);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error("Error check your internet connection");
      });
  };
  console.log("selected", selected);

  const mapAllDeposits = deposits
    .reverse()
    .filter((v) => v.pending == selected)
    .map((v) => (
      <tr key={v} className="row1">
        <td className="trn_user">
          {" "}
          <b>{v?.user?.email}</b>
          <Link
            className="badge badge-danger"
            to={`/edit-user/${v?.user?._id}/${v?.user?.username}`}
          >
            edit
          </Link>{" "}
          <Link
            className="badge badge-info"
            to={`/edit-user-funds/${v?.user?._id}/${v?.user?.username}`}
          >
            manage
          </Link>
          <br />{" "}
          <small style={{ color: "gray" }}>
            <b>Deposit returned to user account:</b> &nbsp; Deposit return
          </small>{" "}
        </td>{" "}
        <td nowrap="" align="right">
          <b style={{ color: "green" }}>${v?.amount}</b>
          
        </td>{" "}
        <td align="center" style={{ width: "77px" }}>
          {v.date}
        </td>{" "}
      </tr>
    ));

  useEffect(() => {
    fetchAllDeposits(window.limit);
  }, []);

  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      className="forTexts"
    >
      {" "}
      <tbody>
        <tr>
          {" "}
          <td width="100%" height="100%" valign="top">
            {" "}
            <h3>Pending Deposits:</h3>{" "}
            {/* <form method="post" name="nform">
              <table className="form">
                {" "}
                <tbody>
                  <tr>
                    {" "}
                    <td>
                      Search:{" "}
                      <input
                        type="text"
                        name="q"
                        value=""
                        className="inpts nosize"
                        style={{ width: "300px" }}
                      />
                    </td>{" "}
                    <td style={{ textAlign: "right" }}>
                      <select
                        onChange={(e) => setSelected(e.target.value)}
                        className="inpts nosize"
                      >
                        <option value="true">Problem</option>
                        <option value="false">Processed</option>{" "}
                      </select>{" "}
                      <input type="submit" value="Go" className="sbmt" />{" "}
                    </td>{" "}
                  </tr>{" "}
                </tbody>
              </table>{" "}
            </form>{" "} */}
            <br />
            <table className="list">
              {" "}
              <tbody>
                <tr>
                  {" "}
                  <th>
                    <a
                      href="javascript:return;"
                      onclick="select_all_transactions()"
                    >
                      #
                    </a>
                  </th>{" "}
                  <th>
                    <a className="sort_link">UserName</a>{" "}
                    <a className="sort_link">↓</a>{" "}
                    <a className="sort_link">↑</a>{" "}
                  </th>{" "}
                  <th>
                    <a className="sort_link">Date</a>{" "}
                    <a className="sort_link">↓</a>{" "}
                    <a className="sort_link">↑</a>{" "}
                  </th>{" "}
                  <th>
                    <a className="sort_link">Amount</a>{" "}
                    <a className="sort_link">↓</a>{" "}
                    <a className="sort_link">↑</a>{" "}
                  </th>{" "}
                  <th>Actions</th>{" "}
                </tr>{" "}
              </tbody>
            </table>
            <table className="list">
              <tbody>
                {loading ? (
                  ""
                ) : (
                  <>
                    <tr>
                      {" "}
                      <th>UserName</th> <th>Amount</th> <th>Date</th>{" "}
                    </tr>

                    {mapAllDeposits.length < 1 ? (
                      <tr>
                        <td colspan="7">No records found</td>
                      </tr>
                    ) : (
                      <>
                        {mapAllDeposits}

                        <center>
                          {" "}
                          <ul className="pagination">
                            <li className="page-item active">
                              <div
                                onClick={fetchAllDeposits}
                                className="page-link"
                              >
                                Show More
                              </div>
                            </li>
                          </ul>{" "}
                        </center>
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </td>{" "}
        </tr>{" "}
      </tbody>
    </table>
  );
};

export default PendingDeposit;
