import React, { useState, useContext, useEffect } from 'react'
 
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {MainContext} from "../../App"


const EditUser = () => {
    const CTX = useContext(MainContext)
    const userID = useParams().id
      const {username, id} = useParams()
      const [loading, setLoading] = useState(false)
      const [inputs, setInputs] = useState({})
      const [select, setSelect] = useState("")
      
// console.log("id ==>> sadds", id);
      

    const saveDetails = (e) => {
        e.preventDefault()
        if(loading) return
        if( inputs?.newPassword?.length < 1 || inputs?.newPassword === inputs.confirmPassword){
            console.log("inputs", inputs)
            setLoading(true)
            let TOASTID = toast.loading(`Saving ${username} details`);
            fetch(`${CTX.url}admin/action/user/${id}`, {
              method: "POST",
              headers: new Headers({"Content-Type" : "application/json", 
              "Authorization": `bearer ${CTX.token}`}),
              body: JSON.stringify({
                    // username: inputs.username,
                    full_name: inputs.full_name,
                    email: inputs.email,
                    balance: inputs.balance,
                    active: select === "active" ? true: false,
                    password: inputs.newPassword,
                    total_deposit: inputs.total_deposit,
                    total_withdrawal: inputs.total_withdrawal
              })
            })
            .then(res => res.json())
            .then(res => {
            setLoading(false)
            fetchEachUser()
            toast.dismiss(TOASTID); // stop the loading icon
            toast.success("Saved details successfully");
            // setMainUser(res.data)
            // setCurrentLimit(res.previous_page * limit)
          })
          .catch(e => {
              setLoading(false)
              toast.dismiss(TOASTID);
              toast.error("Error check your internet connection");
              console.log(e);
            })
        } else {
          console.log("inputs?.newPassword ==> ", inputs?.newPassword?.length);
          console.log("inputs.confirmPassword ==> ", inputs.confirmPassword);
            toast.error("Passwords do not match");   
        }
    }


    const fetchEachUser = () => {
      setLoading(true)
      let TOASTID = toast.loading("Settings up contents");
  fetch(`${CTX.url}admin/action/user/${id}`, {
    method: "GET",
    headers: new Headers({"Content-Type" : "application/json", 
    "Authorization": `bearer ${CTX.token}`}),
  })
  .then(res => res.json())
  .then(res => {
    toast.dismiss(TOASTID); // stop the loading icon
    toast.success(`Loaded ${username} successfully`);
    // setDeposit(res.allDeposit)
    setLoading(false)
    setInputs(res.data)
    // setNextPage(res.next_page)
    // setCurrentLimit(res.previous_page * limit)
  })
  .catch(e => {
    setLoading(false)
    toast.dismiss(TOASTID);
    toast.error("Error check your internet connection");
    console.log(e);
  })
    }

    useEffect(() => {
      fetchEachUser()
    },[])

    
    const onChangeWalletHandler = (name, e) =>  {
      const spread = {...inputs}
      spread.wallet[name].address = e.target.value
      setInputs(spread)
    }

    return (
        <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Edit User:</h3>        
        <Toaster position="top-right" />
         <form onSubmit={saveDetails}>
            
            <table class="form">  <tbody><tr> <th>Full name:</th> <td>
                
                <input type="text"  class="inpts" value={inputs?.full_name} onChange={(e) => setInputs({...inputs,full_name: e.target.value })} /></td> </tr> 

                <tr> <th>Username:</th> <td>
                
                <input type="text"  class="inpts" value={inputs?.username} readOnly /></td> </tr> 
                
                 <tr> <th>Status:</th> <td><select onChange={e => setSelect(e.target.value)} class="inpts"> <option value="active" >Active </option><option value="disabled">Disabled </option>
                 </select> </td> </tr>

                 <tr> <th>Balance:</th> <td>
                
                <input type="text" value={inputs?.balance} onChange={(e) => setInputs({...inputs,balance: e.target.value })} class="inpts"  /></td> </tr>

                {/* <tr> <th>Total Deposit:</th> <td>
                
                <input type="text" value={inputs?.total_deposit} onChange={(e) => setInputs({...inputs,total_deposit: e.target.value })} class="inpts"  /></td> </tr> */}




                <tr> <th>E-mail:</th> <td>
                
                <input type="text" value={inputs?.email} onChange={(e) => setInputs({...inputs,email: e.target.value })} class="inpts"  /></td> </tr>
                
                <tr> <th>{username} password:</th> <td>
                
                <input type="raw"  class="inpts" value={inputs?.password?.raw} readOnly /></td> </tr>

                 <tr> <th>Password:</th> <td>
                
                <input type="raw"  class="inpts" value={inputs?.newPassword} onChange={(e) => setInputs({...inputs, newPassword: e.target.value })} /></td> </tr>
                <tr> <th>Retype password:</th> <td>
                
                <input type="text"  class="inpts" value={inputs?.confirmPassword} onChange={(e) => setInputs({...inputs,confirmPassword: e.target.value })} /></td> </tr> 
                
                 
                
            
                

               
                
 
                
                
                
{/*                       
                <tr> <th>Secret Question:</th> <td>
                
                <input type="text" name="sq" value="Number" class="inpts" /></td> </tr><tr> <th>Secret Answer:</th> <td>
                
                <input type="text" name="sa" value="11" class="inpts" /></td> </tr>  <tr> <th>Auto-withdrawal Enabled:</th> <td> <select name="auto_withdraw" class="inpts"> <option value="1" selected="">Yes</option> <option value="0">No</option> </select> </td> </tr> <tr> <th>Pay Earnings Automatically:</th> <td> <select name="admin_auto_pay_earning" class="inpts"> <option value="1">Yes</option> <option value="0" selected="">No</option> </select> </td> </tr>  */}
                
                
                
                {/* <tr> <th>Demo Account:</th> <td>
                <input type="checkbox" name="demo_acc" value="1" /></td> </tr> */}

                {/* <tr> <th>Admin Note:</th> <td><textarea name="admin_desc" class="inpts"></textarea></td> </tr>  */}
                
                
                <tr> <th>Total Deposit:</th> <td>
                
                <input type="text" value={inputs?.total_deposit} onChange={(e) => setInputs({...inputs,total_deposit: e.target.value })} class="inpts"  /></td> </tr>


                <tr> <th>Total Withdrawal:</th> <td>
                
                <input type="text" value={inputs?.total_withdrawal} onChange={(e) => setInputs({...inputs,total_withdrawal: e.target.value })} class="inpts"  /></td> </tr>
                
                 </tbody></table>  <br /> <center> 
                
                <input type="submit" value="Save" onClick={saveDetails} class="btn btn-success sbmt" /> </center> </form>   </td> </tr> </tbody></table>
    )
}

export default EditUser
