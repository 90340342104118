import React, {useState, useEffect, useContext} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {MainContext} from '../../App';
import {Link} from 'react-router-dom';

const Withdrawal = () => {
  const CTX = useContext(MainContext);
  const [filterCU, setFilterCU] = useState('all');
  const [loading, setLoading] = useState(false);
  const [withdrawal, setWithdrawal] = useState([]);

  const fetchAllWithdrawal = () => {
    setLoading(true);
    let TOASTID = toast.loading('Settings up contents');
    fetch(`${CTX.url}admin/action/withdraw`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('All withdrawals ');
        setWithdrawal(res.data);
        setLoading(false);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  };

  useEffect(() => {
    fetchAllWithdrawal();
  }, []);

  const confirmWithdrawal = (id, e) => {
    let TOASTID = toast.loading('Confirming deposit');
    fetch(`${CTX.url}admin/action/withdraw/${id}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('All withdrawals ');
        fetchAllWithdrawal();
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  };

  const deleteWithdrawal = (id, e) => {
    let TOASTID = toast.loading('Confirming deposit');
    fetch(`${CTX.url}admin/action/withdraw/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('All withdrawals ');
        fetchAllWithdrawal();
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  }

  const mapWithdrawal = withdrawal
    .reverse()
    .filter((v) =>
      filterCU === 'all' ? v?.wallet !== filterCU : v?.wallet === filterCU
    )
    .map((v, i) => {
      return (
        <tr
          style={{backgroundColor: '#fce7ca', width: '100%'}}
          class="row1"
          key={i}
        >
          <td class="trn_user">
            {' '}
            <b>{v?.user?.full_name}</b>
            <div onClick={deleteWithdrawal.bind(this, v._id)} class="badge badge-danger">delete</div>
            {v.pending && (
              <div
                class="badge badge-info"
                onClick={confirmWithdrawal.bind(this, v._id)}
              >
                confirm
              </div>
            )}
            <br />
            <small style={{color: 'gray'}}>
              <b style={{textTransform: 'capitalize'}}>
                {v?.wallet.name} Withdrawal:
              </b>{' '}
              &nbsp; {v?.wallet?.address}
            </small>{' '}
          </td>{' '}
          <td nowrap="" align="right">
            <b style={{color: 'red'}}>${v.amount}</b>{' '}
            
          </td>{' '}
          <td align="center">
            <small>{v.date}</small>
          </td>{' '}
        </tr>
      );
    });

  const allECurrency = [...new Set([...withdrawal.map((v) => v.wallet.name)])];

  const mappAllCUOptions = allECurrency.map((v) => (
    <option value={v}>{v}</option>
  ));

  const onChangeSelectECUHandler = (e) => {
    setFilterCU(e.target.value);
  };

  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      class="forTexts"
    >
      {' '}
      <tbody>
        <tr>
          <td width="100%" height="100%" valign="top">
            <h3>Transactions History:</h3>

            <form method="post" name="trans">
              {' '}
              <table class="form controls nosize">
                {' '}
                <tbody>
                  <tr>
                    {' '}
                    <td>
                      <br />{' '}
                      <select class="inpts" onChange={onChangeSelectECUHandler}>
                        {' '}
                        <option value="all">All eCurrencies</option>{' '}
                        {mappAllCUOptions}{' '}
                      </select>{' '}
                    </td>{' '}
                  </tr>{' '}
                </tbody>
              </table>{' '}
            </form>

            <table className="list">
              <tbody>
                <tr>
                  {' '}
                  <th>UserName</th> <th>Amount</th> <th>Date</th>{' '}
                </tr>

                {/* <tr class="row1"> <td class="trn_user">  <b>Ikechukwu</b> <a class="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=5">edit</a> <a class="badge badge-info" target="_blank" href="?a=user_details&amp;id=5">manage</a> <br /> <small style={{color:"gray"}}><b>Withdrawal:</b> &nbsp; Withdraw processed to account bc1qkgzrewkkkwjm3lka4d59e3l0lh0taufnau54ps. Batch is 8434b2ae77ddbfaedead409e72298daedeae82873dbf3d85848fbafb153c1f08</small> </td> <td nowrap="" align="right"><b style={{color:"red"}}>$496.42</b> <img src={require("../../btc.gif")} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-13-2022<br />03:26:09 PM</small></td> </tr>
        
        
        
        <tr class="row1"> <td class="trn_user">  <b>Ikechukwu</b> <a class="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=5">edit</a> <a class="badge badge-info" target="_blank" href="?a=user_details&amp;id=5">manage</a> <br /> <small style={{color:"gray"}}><b>Withdrawal:</b> &nbsp; Withdraw processed to account bc1qkgzrewkkkwjm3lka4d59e3l0lh0taufnau54ps. Batch is 8434b2ae77ddbfaedead409e72298daedeae82873dbf3d85848fbafb153c1f08</small> </td> <td nowrap="" align="right"><b style={{color:"red"}}>$496.42</b> <img src={require("../../btc.gif")} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-13-2022<br />03:26:09 PM</small></td> </tr>
        
        
        
        <tr class="row1"> <td class="trn_user">  <b>Ikechukwu</b> <a class="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=5">edit</a> <a class="badge badge-info" target="_blank" href="?a=user_details&amp;id=5">manage</a> <br /> <small style={{color:"gray"}}><b>Withdrawal:</b> &nbsp; Withdraw processed to account bc1qkgzrewkkkwjm3lka4d59e3l0lh0taufnau54ps. Batch is 8434b2ae77ddbfaedead409e72298daedeae82873dbf3d85848fbafb153c1f08</small> </td> <td nowrap="" align="right"><b style={{color:"red"}}>$496.42</b> <img src={require("../../btc.gif")} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-13-2022<br />03:26:09 PM</small></td> </tr>
         */}

                {mapWithdrawal.length > 0 ? mapWithdrawal: "No record found"}

              </tbody>
            </table>


            {/* <center>
              <ul class="pagination">
                <li class="page-item">
                  <a class="prev page-link disabled">&lt;&lt;</a>
                </li>
                <li class="page-item active">
                  <a class="page-link">1</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:go(2)">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:go(3)">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="next page-link" href="javascript:go(2)">
                    &gt;&gt;
                  </a>
                </li>
                <li class="page-item">
                  <a class="last page-link" href="javascript:go(3)">
                    &gt;&gt;&gt;
                  </a>
                </li>
              </ul>
            </center> */}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Withdrawal;
