import React, {useState, useEffect, useContext} from 'react';
import {Link} from "react-router-dom"
import toast, { Toaster } from "react-hot-toast";
import {MainContext} from "../../App"

const WithdrawalRequest = () => {
    const CTX = useContext(MainContext)
    const [filterCU, setFilterCU] = useState("all")
    const [loading, setLoading] = useState(false)
    const [withdrawal, setWithdrawal] = useState([])
    
        const fetchAllWithdrawal = () => {
          setLoading(true)
            let TOASTID = toast.loading("Settings up contents");
        fetch(`${CTX.url}admin/action/withdraw`, {
          method: "GET",
          headers: new Headers({"Content-Type" : "application/json", 
          "Authorization": `bearer ${CTX.token}`}),
        })
        .then(res => res.json())
        .then(res => {
          toast.dismiss(TOASTID); // stop the loading icon
          toast.success("All withdrawals ");
          setWithdrawal(res.data)
          setLoading(false)
          // setCurrentLimit(res.previous_page * limit)
        })
        .catch(e => {
          setLoading(false)
          toast.dismiss(TOASTID);
          toast.error("Error check your internet connection");
          console.log(e);
        })
        }
    
    useEffect(() => {
        fetchAllWithdrawal()
    },[])

    const confirmWithdrawal = (id, e) => {
      let TOASTID = toast.loading('Confirming deposit');
      fetch(`${CTX.url}admin/action/withdraw/${id}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `bearer ${CTX.token}`,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss(TOASTID); // stop the loading icon
          toast.success('All withdrawals ');
          fetchAllWithdrawal();
          // setCurrentLimit(res.previous_page * limit)
        })
        .catch((e) => {
          toast.dismiss(TOASTID);
          toast.error('Error check your internet connection');
          console.log(e);
        });
    }

    const deleteWithdrawal = (id, e) => {
      let TOASTID = toast.loading('Confirming deposit');
      fetch(`${CTX.url}admin/action/withdraw/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `bearer ${CTX.token}`,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss(TOASTID); // stop the loading icon
          toast.success('All withdrawals ');
          fetchAllWithdrawal();
          // setCurrentLimit(res.previous_page * limit)
        })
        .catch((e) => {
          toast.dismiss(TOASTID);
          toast.error('Error check your internet connection');
          console.log(e);
        });
    }

    const mapWithdrawal = withdrawal.reverse().filter(v => filterCU === "all"   ?  v?.wallet !== filterCU  : v?.wallet === filterCU ).map((v, i) => {
        return(
        <tr style={{backgroundColor: "#fce7ca", width: "100%"}} class="row1" key={i}> 
        <td class="trn_user">  <b>{v?.user?.full_name}</b> 
            <div onClick={deleteWithdrawal.bind(this, v._id)} class="badge badge-danger" >delete</div>
            {v.pending && <div class="badge badge-info" onClick={confirmWithdrawal.bind(this,v._id)} >confirm</div> }<br />

             <small style={{color:"gray"}}><b style={{textTransform: "capitalize"}}>{v.wallet.name} Withdrawal:</b> &nbsp; {v?.wallet?.address}</small> </td> <td nowrap="" align="right"><b style={{color:"red"}}>${v.amount}</b> 
             {/* <img src={v?.user?.wallet[v?.wallet]?.image} align="absmiddle" hspace="1" height="17" /> */}
             
             </td> <td align="center"><small>{v.date}</small></td> </tr>
        )
    })



    const allECurrency = [...new Set([...withdrawal.map(v => v.wallet.name)])]

    const mappAllCUOptions = allECurrency.map(v =>  <option value={v}>{v}</option>)

    const onChangeSelectECUHandler = (e) => {
      setFilterCU(e.target.value)
    }




    return (
        <table width="100%" height="100%" border="0" cellpadding="10" cellspacing="0" className="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">   <h3>Withdrawal Requests:</h3>  
        
        
        
        <form method="post" name="trans"> <table className="form controls nosize"> <tbody><tr> <td> 
                <br /> <select name="ec" class="inpts" onChange={onChangeSelectECUHandler}> <option value="all">All eCurrencies</option> {mappAllCUOptions} </select>
                 </td> </tr>

 </tbody></table>    <table className="list"> <tbody><tr> <th><a href="javascript:return;" onclick="select_all_transactions()">#</a></th>  <th><a  className="sort_link"> Amount</a> <a  className="sort_link">↓</a> <a  className="sort_link">↑</a> </th> <th><a  className="sort_link">Date</a> <a  className="sort_link">↓</a> <a  className="sort_link">↑</a> </th> </tr>   
 
 
 {mapWithdrawal.length > 0 ? mapWithdrawal : "No record found"}
 
 
 </tbody></table> </form> 
 
 
 {/* <br /> <center>  <input type="button" value="Mass payment" className="btn btn-info sbmt" title="Automatically pay withdrawl requests" /> &nbsp; <input type="button" value="Remove" className="btn btn-warning sbmt" title="Remove withdrawal requests and return funds to user's balance" onclick="func2();" /> &nbsp; <input type="button" value="Assure" className="btn btn-danger sbmt" title="Assure withdrawal requests as processed. It means you already process the transactions outside the system and just notify it transactions was completed." onclick="func3();" /> &nbsp; <ul className="pagination"><li className="page-item"><a className="prev page-link disabled">&lt;&lt;</a></li><li className="page-item active"><a className="page-link">1</a></li><li className="page-item"><a className="next page-link disabled">&gt;&gt;</a></li></ul> </center>  */}
 
 
  </td> </tr> </tbody></table>
    )
}

export default WithdrawalRequest
