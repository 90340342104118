import BTC from "../../btc.gif";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { MainContext } from "../../App";

const Deposit = () => {
  const CTX = useContext(MainContext);
  const [nextPage, setNextPage] = useState(10);
  const [filterCU, setFilterCU] = useState("all");
  const [loading, setLoading] = useState(false);

  // const [deposits, setDeposit] = useState([1,20,3,4,5,6,7,8,9])
  const [deposits, setDeposit] = useState([]);

  const fetchAllDeposits = () => {
    setLoading(true);
    let TOASTID = toast.loading("Settings up contents");
    // fetch(`${CTX.url}admin/action/list-deposit?page=1&limit=${nextPage}`, {
      fetch(`${CTX.url}admin/action/list-deposit`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success("All deposits ");
        setDeposit(res.allDeposit);
        setLoading(false);
        setNextPage(res.allDeposit.length + 10);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error("Error check your internet connection");
        console.log(e);
      });
  };

  useEffect(() => {
    fetchAllDeposits();
  }, []);

  const mapAllDeposits = deposits.filter(v => v.pending)
    .filter((v) =>
      filterCU === "all" ? v?.wallet !== filterCU : v?.wallet === filterCU
    )
    .map((v) => (
      <tr key={v} className="row1">
        <td className="trn_user">
          {" "}
          <b>{v?.user?.email}</b>{" "}
          <Link
          style={{paddingLeft: 8, paddingRight: 7, color: "#fff"}}
            className="badge badge-danger"
            to={`/edit-user/${v?.user?._id}/${v?.user?.username}`}
          >
            edit
          </Link>{" "}
          <Link
          style={{paddingLeft: 8, paddingRight: 7, color: "#fff"}}
            className="badge badge-info"
            to={`/edit-user-funds/${v?.user?._id}/${v?.user?.username}`}
          >
            manage
          </Link>
          <br />{" "}
          <small style={{ color: "gray" }}>
            <b>Username: {v?.user?.username}</b> &nbsp; Current balance: ${v?.user?.balance}
          </small>{" "}
        </td>{" "}
        <td nowrap="" align="right">
          <b style={{ color: "green" }}>${v?.amount}</b>
        </td>{" "}
        <td align="center" style={{ width: "77px" }}>
          {v.date}
        </td>{" "}
      </tr>
    ));

  const allECurrency = [...new Set([...deposits.map((v) => v.wallet)])];

  // console.log("deposits ==>> HELLO WORLD ==>> ", deposits);
  // console.log("allECurrency ==>> HELLO WORLD allECurrency ==>> ", allECurrency);
  const mappAllCUOptions = allECurrency.map((v) => (
    <option value={v}>{v}</option>
  ));

  const onChangeSelectECUHandler = (e) => {
    setFilterCU(e.target.value);
  };

  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      className="forTexts"
    >
      {" "}
      <tbody>
        <tr>
          {" "}
          <td width="100%" height="100%">
            <Toaster position="top-right" />

            <h3>Deposit History:</h3>

            <form method="post" name="trans">
              {" "}
              <table className="form controls nosize">
                {" "}
                <tbody>
                  <tr>
                    {" "}
                    <td>
                      <br />{" "}
                      <select
                        name="ec"
                        class="inpts"
                        onChange={onChangeSelectECUHandler}
                      >
                        {" "}
                        <option value="all">All eCurrencies</option>{" "}
                        {mappAllCUOptions}{" "}
                      </select>{" "}
                    </td>{" "}
                  </tr>
                </tbody>
              </table>{" "}
              <table className="list">
                {" "}
                <tbody>
                  <tr>
                    {" "}
                    <th>
                      <a
                        href="javascript:return;"
                        onclick="select_all_transactions()"
                      >
                        #
                      </a>
                    </th>{" "}
                    <th>
                      <a className="sort_link">UserName</a>{" "}
                      <a className="sort_link">↓</a>{" "}
                      <a className="sort_link">↑</a>{" "}
                    </th>{" "}
                    <th>
                      <a className="sort_link">Date</a>{" "}
                      <a className="sort_link">↓</a>{" "}
                      <a className="sort_link">↑</a>{" "}
                    </th>{" "}
                    <th>
                      <a className="sort_link">Amount</a>{" "}
                      <a className="sort_link">↓</a>{" "}
                      <a className="sort_link">↑</a>{" "}
                    </th>{" "}
                    <th>Actions</th>{" "}
                  </tr>{" "}
                </tbody>
              </table>{" "}
            </form>

            <table className="list">
              <tbody>
                {loading ? (
                  ""
                ) : (
                  <>
                    <tr>
                      {" "}
                      <th>UserName</th> <th>Amount</th> <th>Date</th>{" "}
                    </tr>

                    {mapAllDeposits.length < 1 ? (
                      <tr>
                        <td colspan="7">No records found</td>
                      </tr>
                    ) : (
                      <>
                        {mapAllDeposits}

                        <center>
                          {" "}
                          <ul className="pagination">
                            <li className="page-item active">
                              {/* <div
                                onClick={fetchAllDeposits}
                                className="page-link"
                              >
                                Show More
                              </div> */}
                            </li>
                          </ul>{" "}
                        </center>
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Deposit;
