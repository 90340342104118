import React, {useContext, useState, useEffect} from 'react';
import Classes from './wallet.module.css';
import {MainContext} from '../../App';
import {ReactComponent as TRASH} from '../../assets/trash.svg';
import toast, {Toaster} from 'react-hot-toast';

const InvestmentPlans = () => {
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([
    {wallet: 'bitcoin', address: 'bc1qzn778hxt9kh2mgsx72pdsnq5phdwczduxqfc8h'},
    {wallet: 'ethereum', address: '0xf0becF8758Ad288bA114eDB75012Bb1A803816f0'},
    {wallet: 'USDT', address: 'TG3Sw9kKs77bu2Q2ZfdVT49bB2pbn2xGaE'},
    {wallet: 'XRP', address: 'rsEUf5RvWoYRq7CePaXJvJCWnknJzfcYkC'},
  ]);

  const fetchAllUser = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let TOASTID = toast.loading('Fetching company plan');
    fetch(`${CTX.url}admin/account/plans`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('Company plans ');
        setPlans(res.data.wallets);
        console.log('res.data => ', res);
        setLoading(false);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  const onChangeHandler = (index, e) => {
    const spread = [...plans];
    console.log('index => ', index);
    spread[index][e.target.name] = e.target.value;
    setPlans(spread);
  };

  const trashHandler = (index, e) => {
    const spread = [...plans];
    const ss = spread.splice(index, 1);
    setPlans(spread);
  };


  const addSubmitHandler = () => {
const spread = [...plans]
spread.push({})
setPlans(spread)
  }

  const mapPlans = plans?.map((v, i) => {
    return (
      <div style={{border: '1px solid #333', position: 'relative'}}>
        {/* <div className={Classes.absoluteDelete}>
          <TRASH
            style={{cursor: 'pointer'}}
            onClick={trashHandler.bind(this, i)}
            width="30px"
          />{' '}
        </div> */}
        <div className={Classes.coverTheMap}>
          <div className={Classes.coverTheEachHere}>
            <label>Wallet</label>
            <input
              value={v?.wallet}
              onChange={onChangeHandler.bind(this, i)}
              name={'wallet'}
            />
          </div>

          <div className={Classes.coverTheEachHere}>
            <label>Address</label>
            <input
              value={v?.address}
              onChange={onChangeHandler.bind(this, i)}
              name="address"
            />
          </div>


      </div>
      </div>
    );
  });

  const submitHandler = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let TOASTID = toast.loading('Posting new plan');
    fetch(`${CTX.url}admin/account/edit`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
      body: JSON.stringify({
        wallets: plans,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('Successful ');
        setPlans(res.date.wallets);
        // console.log('res.data => ', res);
        // console.log('res.data => ', res);
        setLoading(false);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  };

  return (
    <div style={{paddingBottom: '3em'}}>
      <div>{mapPlans}</div>

      {/* <button onClick={submitHandler} style={{marginTop: '20px'}}>
        Submit
      </button>


      <button onClick={addSubmitHandler} style={{marginTop: '20px', marginLeft: "20px"}}>
        Add another section
      </button> */}
    </div>
  );
};

export default InvestmentPlans;
