import React, { useState } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { ReactComponent as AnoLogo } from "./anologo.svg";
import './App.css';
import BlackList from "./pages/accountBlacklist/blacklist";
import AddFund from "./pages/addFund/addFund";
import Add from "./pages/add_bonus/add_bonus";
import AutoPay from './pages/autoPay/autoPay';
import Deposit from "./pages/deposit/deposit";
import EarningHoliday from "./pages/earningHoliday/earningHoliday";
import Earning from "./pages/earnings/earnings";
import EditUser from './pages/edit-user/edit-user';
import EditEmailTemplate from './pages/editEmailTemplate/editEmailTemplate';
import EmailTemplate from './pages/emailTemplate/emailTemplate';
import ExHistory from "./pages/exchangeHistory/exchangeHistory";
import ExRate from "./pages/exchangeRate/exchangeRate";
import ExpiryDeposit from "./pages/expiryDeposit/expiryDeposit";
import GettingStarted from "./pages/gettingStarted/gettingStarted";
import InfoBox from "./pages/infoBox/infoBox";
import InvestmentPackages from "./pages/investmentPackages/investmentPackages";
import InvestmentPlans from './pages/investmentPlans/investmentPlans';
import LinkReplacement from './pages/linkReplacement/linkReplacement';
import Login from './pages/login/login';
import Maintenance from './pages/mentenance/mentenance';
import News from './pages/news/news';
import NewsLetter from "./pages/newsLetter/newsLetter";
import Notice from './pages/notice/notice';
import Penalty from './pages/penalty/penalty';
import Pending from "./pages/pending_deposit/pending_deposit";
import Processings from './pages/processing/processing';
import Commission from "./pages/referral/referral";
import ReferralCommission from './pages/referralCommission/referralCommission';
import Security from './pages/security/security';
import Settings from "./pages/settings/settings";
import TopReferralEarnings from "./pages/topReferrial/topReferrial";
import Transaction from "./pages/transaction/transaction";
import UserDeposit from './pages/userDeposit/userDeposit';
import UserFunds from './pages/userFunds/userFunds';
import UserReferral from './pages/userRefferal/userReferral';
import User from "./pages/users/user";
import UserTraffic from './pages/userTraffic/userTraffic';
import WithDrawal from "./pages/withdrawal/withdrawal";
import WithdrawalRequest from "./pages/withdrawalRequest/withdrawalRequest";
import Wallets from './pages/wallet/wallet'
import QGif from "./q.gif";
import favIcon from './assets/favicon.png'


function App() {

  let [auth, setauth] = useState(false);

  const url = "https://server.vintageguarantee.com/";
  // const url = 'http://localhost:5005/';

  // const [token, setToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdSI6IjYzMDZhMTk1MDk1Y2MxZTg0MWM4NzgzZiIsImlhdCI6MTY2MTQxNjMyMH0.ixo5PuBSLFm98ob4Esjy6tYSfa-LNZuUStzo_Y7iewQ")
  const [token, setToken] = useState("")

  const checkAdminDetails =(usr, pwd, e) => {
    e.preventDefault()
    console.log("usr => ", usr)
    console.log("pwd => ", pwd)
    let TOASTID = toast.loading("Settings up contents");
    fetch(`${url}admin/account/login`, {
      method: "POST",
      headers: new Headers({"Content-Type" : "application/json"}),
      body:  JSON.stringify({
        username: usr,
        password: pwd
    })
    })
    .then(res => res.json())
    .then(res => {
      toast.dismiss(TOASTID); // stop the loading icon
      if(res.error){
        toast.error(res.msg);
        return
      }
      toast.success(res.data);
      setToken(res.token)
      setauth(true)
    })
    .catch(e => {
      toast.dismiss(TOASTID);
      toast.error("Error check your internet connection");
      console.log(e);
      // window.location.reload()
    })
  }
    




  return (
    <MainContext.Provider value={{url, token }} >
        <Toaster position="top-right" />
          <center>
      <table width="760" border="0" cellPadding="0" cellSpacing="0">
        <tbody>
          {/* Top Level Section */}
          {/* Top Level Section */}
          {/* Top Level Section */}
          {/* Top Level Section */}
          <tr>
            <td valign="top">
              <table cellSpacing="0" cellPadding="0" border="0" width="100%">
                <tbody>
                  <tr>
                    <td background="images/ver.gif" bgcolor="#FF8D00">
                      {/* <img src={AnoLogo} border="0" align="left" /> */}
                      {/* <AnoLogo style={{margin: "30px"}} /> */}
                      <img src={favIcon} alt='logo' style={{width: '70px', margin: '30px'}} />
                    </td>
                    <td
                      background="images/ver.gif"
                      bgcolor="#FF8D00"
                      valign="bottom"
                      align="right"
                    >
                      <span
                        style={{
                          fontFamily: 'verdana',
                          fontSize: '12px',
                          color: 'white',
                        }}
                      >
                        <b>
                          <div id="google_translate_element"></div>
                          <a
                            style={{marginRight: '13px'}}
                            href="?"
                            className="toplink"
                          >
                            Home
                          </a>
                          <span></span>
                          <a href="https://docs.vintageguarantee.com/" className="toplink">
                            Logout
                          </a>
                        </b>
                      </span>
                      &nbsp; &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          {/* main body section */}
          {/* main body section */}
          {/* main body section */}
          {/* main body section */}
          <tr>
            <td valign="top">
              <table
                cellSpacing="0"
                cellPadding="1"
                border="0"
                width="100%"
                bgcolor="#ff8d00"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        width="100%"
                        height="100%"
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          <tr bgcolor="#FFFFFF" valign="top">
                            <td width="100" align="center">
                              
                              <table
                                cellSpacing="0"
                                cellPadding="2"
                                border="0"
                                style={{width: "150px", display: auth ? 'block' : "none" }}
                                width="102"
                              >
                                
                                <tbody>
                                  <tr>
                                    
                                    <th colspan="2">
                                      <img
                                        src="images/q.gif"
                                        width="1"
                                        height="3"
                                      />
                                    </th>
                                  </tr>
                                  <tr>
                                    
                                    <th colspan="2" className="title">
                                      Menu
                                    </th>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/investment_packages">Investment Packages</Link>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/expiring_deposits">
                                        Expiring Deposits
                                      </Link>
                                    </td>
                                  </tr> */}
                                  {/* <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>blacklist
                                  </tr> */}
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/users">Users</Link>
                                    </td>
                                  </tr>
                                  
                                  
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                  {/* <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/transactions">Transactions</Link>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/withdrawal_requests">
                                        Withdrawal_Requests
                                      </Link>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/add_funds">
                                        Add Funds
                                      </Link>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/deposit">
                                        Pending Deposits
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/withdrawal">
                                        Withdrawals
                                      </Link>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/commissions">
                                        Referral
                                      </Link>
                                    </td>
                                  </tr> */}
                                  {/* <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/earning">
                                        Earnings
                                      </Link>
                                    </td>
                                  </tr> */}
                                  {/* <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/add_transactions">
                                        Add Bonus
                                      </Link>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <Link to="/pending_deposits">
                                        Approved Deposits
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                  
                                  
                                 
                                 
                                
                                  {/* <tr>
                                    <td className="menutxt">
                                      <Link to="/investment-plans">Investment Plans</Link>
                                    </td>
                                  </tr> */}
                                  
                                  <tr>
                                    <td className="menutxt">
                                      <Link to="/wallets">Wallets</Link>
                                    </td>
                                  </tr>
                                  
                                 
                                  
                                 
                                  
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                
                                 
                                
                                 
                                
                                  
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">&nbsp;</td>
                                  </tr>
                                  <tr>
                                    
                                    <td className="menutxt">
                                      <a href="/">Logout</a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>

                            <td bgcolor="#ff8d00" valign="top" width="1"><img src={QGif} width="1" height="1" /></td>
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            {/* another subSection from the main table */}
                            <td width="570" align="center">

          {/* <Route path='/login' element={<Login OnSubmitFormHandler={checkAdminDetails} />} />  */}
          {/* {!auth && <Route path='/user' element={<Navigate replace to={'/login'}/>} /> } */}
                              
          {/* <Route path='/' exact element={<Navigate replace to={'/getting-started'}/>} /> */}
                            
{!auth &&               
<Routes>
<Route path='/*' element={<Navigate replace to={'/login'}/>} />
          <Route path='/login' element={<Login OnSubmitFormHandler={checkAdminDetails} />} /> 
                            </Routes>
}

                          
                          
                          
        {auth &&                  
          <Routes> 
<Route path='/login' element={<Navigate replace to={'/getting-started'}/>} />
          <Route path='/getting-started' element={<GettingStarted/>  } />
          <Route path='/expiring_deposits' element={<ExpiryDeposit/>  } />
          <Route path='/investment_packages' element={<InvestmentPackages/>  } />
          <Route path='/users' element={<User/>  } />
          <Route path='/top_referral_earnings' element={<TopReferralEarnings/>  } />
          <Route path='/ext_accounts_blacklist' element={<BlackList/>  } />
          <Route path='/transactions' element={<Transaction/>  } />
          <Route path='/transactions/:id' element={<Transaction/>  } />
          <Route path='/withdrawal_requests' element={<WithdrawalRequest />  } />
          <Route path='/withdrawal_requests/:id' element={<WithdrawalRequest />  } />
          <Route path='/add_funds' element={<AddFund />  } />
          <Route path='/deposit' element={<Deposit />  } />
          <Route path='/deposit/:id' element={<Deposit />  } />
          <Route path='/user-deposit/:id' element={<UserDeposit />  } />
          <Route path='/withdrawal' element={<WithDrawal />  } />
          <Route path='/withdrawal/:id' element={<WithDrawal />  } />
          <Route path='/commissions' element={<Commission />  } />
          <Route path='/earning' element={<Earning />  } />
          <Route path='/earning/:id' element={<Earning />  } />
          <Route path='/pending_deposits' element={<Pending />  } />
          <Route path='/exchange' element={<ExHistory />  } />
          <Route path='/add_transactions' element={<Add />  } />
          <Route path='/add_transactions/:id/:username' element={<Add />  } />
          <Route path='/penalty/:id' element={<Penalty />  } />
          <Route path='/user-referral/:id' element={<UserReferral />  } />
          <Route path='/user-traffic/:id' element={<UserTraffic />  } />
          <Route path='/referral-commission/:id' element={<ReferralCommission />  } />
          <Route path='/exchange_rates' element={<ExRate />  } />
          <Route path='/settings' element={<Settings />  } />
          <Route path='/processings' element={<Processings />  } />
          <Route path='/auto-pay-settings' element={<AutoPay />  } />
          <Route path='/info_box_settings' element={<InfoBox />  } />
          <Route path='/earning_holidays' element={<EarningHoliday />  } />
          <Route path='/newsletter' element={<NewsLetter />  } />
          <Route path='/seo_links' element={<LinkReplacement />  } />
          <Route path='/email_templates' element={<EmailTemplate />  } />
          <Route path='/security' element={<Security />  } />
          <Route path='/maintenance_page' element={<Maintenance />  } />
          <Route path='/user_notices' element={<Notice />  } />
          <Route path='/news' element={<News />  } />
          <Route path='/edit-email-template' element={<EditEmailTemplate />  } />
          <Route path='/edit-user/:id/:username' element={<EditUser />  } />
          <Route path='/edit-user-funds/:id/:username' element={<UserFunds />  } />
          <Route path='/investment-plans' element={<InvestmentPlans />  } />
          <Route path='/wallets' element={<Wallets />  } />
          
          </Routes>
}








                              
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          {/* Footer Section */}
          {/* Footer Section */}
          {/* Footer Section */}
          {/* Footer Section */}
          <tr>
            <td height="19" bgcolor="ff8d00">
              {/* */}
            </td>
          </tr>
        </tbody>
      </table>
    </center>
    </MainContext.Provider>
  );
}

export default App;




export const MainContext = React.createContext({
  url: null,
  token: null
})